import './Header&Footer.css';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// import domain from '../utils/constant';

function Header() {
	const location = useLocation();
	const viewportWidth = window.screen.width;
	const headerRef = useRef(null);
	// const [headerHeight, setHeaderHeight] = useState(0);
	const [showMobileMenu, setShowMobileMenu] = useState(
		viewportWidth <= 768 ? false : true
	);
	useEffect(() => {
		if (headerRef.current) {
			const height = headerRef.current.getBoundingClientRect().height;
			// setHeaderHeight(height);
			document.documentElement.style.setProperty(
				'--headerHeight',
				`${height}px`
			);
		}
	}, []);
	useEffect(() => {
		setShowMobileMenu(viewportWidth <= 768 ? false : true);
	}, [location, viewportWidth]);

	return (
		<header ref={headerRef}>
			<Link to={`/`} className="logo-container">
				<img
					// src={`${imageBaseUrl}/logo.png`}
					src="/logo.png"
					alt="logo"
					className="header-logo"
				/>
			</Link>
			<div
				className="mobile-menu"
				style={{ display: viewportWidth <= 768 ? 'flex' : 'none' }}>
				<div
					className="menu-link"
					onClick={() => setShowMobileMenu(!showMobileMenu)}>
					<img
						src="/bars-solid.svg"
						alt="menu btn"
						className="menu-icon"
					/>
				</div>
			</div>
			<div
				className="menu-container"
				style={{ display: showMobileMenu ? 'flex' : 'none' }}>
				<img
					src="/x-solid.svg"
					alt="cart"
					style={{
						width: '8%',
						display: viewportWidth > 768 ? 'none' : 'block',
					}}
					onClick={() => setShowMobileMenu(!showMobileMenu)}
					className="btn"
				/>
				<div
					className="menu-link-border"
					style={{ marginTop: viewportWidth <= 768 ? '2rem' : '0' }}>
					<Link to={`/aboutus`} className="menu-link">
						About Us
					</Link>
				</div>
				<div className="menu-link-border">
					<Link to={`/products`} className="menu-link">
						Products
					</Link>
				</div>
				<div className="menu-link-border">
					<Link to={`/franchise`} className="menu-link">
						Franchise
					</Link>
				</div>
				<div className="menu-link-border">
					<Link to={`/promotions`} className="menu-link">
						Promotions
					</Link>
				</div>
				<div className="menu-link-border">
					<Link to={`/news`} className="menu-link">
						News
					</Link>
				</div>
				<div className="menu-link-border">
					<Link to={`/contactus`} className="menu-link">
						Contact Us
					</Link>
				</div>
			</div>
		</header>
	);
}

export default Header;
