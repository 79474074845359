import './Entry.css';
import { Link } from 'react-router-dom';
import constants from '../utils/constants.json';
import { useNavigate } from 'react-router-dom';

function Entry() {
	const navigate = useNavigate();

	const contentTable = [
		{
			title: 'About Us',
			image: 'about-us',
			des: 'Learn more about us to get success together.',
			path: 'aboutus',
		},
		{
			title: 'Franchise',
			image: 'franchise',
			des: 'Work together to sin the market and double our success together',
			path: 'franchise',
		},
		{
			title: 'Find Us',
			image: 'find-us',
			des: 'Get the location of each LOCKA and start experience the benefit from our service.',
			path: 'findus',
		},
		{
			title: 'Promotion',
			image: 'promotion',
			des: 'Get more discount from our different partners.',
			path: 'promotion',
		},
		{
			title: 'News',
			image: 'news',
			des: 'Keep following us on our new products and development.',
			path: 'news',
		},
		{
			title: 'Contact Us',
			image: 'contact-us',
			des: 'Call us now to start your successful journey with us together.',
			path: 'contactus',
		},
	];
	return (
		<div className="page-root">
			<div className="banner">
				<div className="banner-logo-container">
					<img src="/logo-text.png" alt="logo" class="text-logo" />
				</div>
				<div className="banner-content">
					<div
						style={{ fontSize: '1.7rem', marginBottom: '3%' }}
						className="orange-color">
						YOUR LOCAL STORAGE PARTNER
					</div>
					<div className="title">Reduce operational costs</div>
					<div className="content">
						No need to plan your storage journey.We located in many
						different district that fits your live.
					</div>
					<div className="title">Affordability, Fast & Flexible</div>
					<div className="content">
						Competitive pricing to save you more! On demand storage starts
						from just $2!
					</div>
					<div className="title">Convenience, All in One APP</div>
					<div className="content" style={{ marginBottom: 0 }}>
						Booking a storage is fast and easy. Make payments and track
						your locka in one app. It's the only on-demand storage
						solution you'll need.
					</div>
				</div>
			</div>
			<div className="entry-content-table-container">
				<div className="content-table-slogan-container">
					<div style={{ color: constants.darkGrey, fontSize: '1.7rem' }}>
						Most Dynamic Solution
					</div>
					<div
						style={{
							color: constants.darkGrey,
							marginLeft: '1%',
							fontSize: '1.2rem',
						}}>
						in managing storage in the world!
					</div>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						flexWrap: 'wrap',
						alignItems: 'center',
					}}
					className="grid-container">
					{contentTable.map((e, i) => (
						<Link key={i} to={`/${e.path}`} className="entry-link-box">
							<div
								style={{
									backgroundImage: `url(/${e.image}.png)`,
									width: '100%',
									backgroundRepeat: 'no-repeat',
									backgroundSize: 'cover',
									backgroundPosition: 'center',
									aspectRatio: 1.5,
									display: 'flex',
									alignItems: 'flex-end',
								}}>
								<div
									style={{
										height: 'fit-content',
										borderLeftColor: constants.orange,
										borderLeftStyle: 'solid',
										textAlign: 'left',
										margin: '5%',
										paddingLeft: '3%',
										fontSize: '1.2rem',
									}}>
									{e.des}
								</div>
							</div>
							<div
								style={{
									height: 'fit-content',
									backgroundColor: constants.blue,
									textAlign: 'right',
									padding: '2%',
									fontSize: '1.6rem',
								}}>
								{e.title}
							</div>
						</Link>
					))}
				</div>
				<div
					style={{
						display: 'flex',
						width: '100%',
						justifyContent: 'space-between',
						maxHeight: 150,
						minHeight: 80,
						marginBottom: '3%',
					}}
					className="app-container">
					<div
						style={{
							color: 'black',
							fontSize: '1.3rem',
							textAlign: 'left',
							flex: 1,
						}}>
						Download our multi-function APP to start the journey!
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							flexWrap: 'wrap',
							flexDirection: 'row',
						}}
						className="app-icon-container-mobile">
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-around',
								marginRight: '5%',
								width: 'fit-content',
								height: '100%',
							}}
							className="app-badge-container">
							<a
								href="https://play.google.com/store/apps/details?id=com.locka"
								style={{
									height: '30%',
									width: 'fit-content',
								}}>
								<img
									src="/GetItOnGooglePlay_Badge_Web_color_English.png"
									alt="app icon"
									style={{
										height: '100%',
										width: '100%',
									}}
									className="app-badge"></img>
							</a>
							<a
								href="https://apps.apple.com/hk/app/locka/id6468265592?l=en-GB"
								style={{ height: '30%', width: '100%' }}>
								<img
									src="/App_Store_Badge.svg"
									alt="app icon"
									style={{
										height: '100%',
										width: '100%',
									}}
									className="app-badge"
								/>
							</a>
						</div>
						<img
							src="/app_icon.jpg"
							alt="app"
							style={{
								width: 'fit-content',
								height: '100%',
								borderRadius: 20,
							}}
							className="app-icon"
						/>
					</div>
				</div>
			</div>
			<div className="shop-now-container">
				<div className="slogan-container">
					<div style={{ fontSize: '1.7rem' }}>Most Unique Design</div>
					<div style={{ marginLeft: '1%', fontSize: '1.2rem' }}>
						in giving best function padlock in the world !
					</div>
				</div>
				<div className="products-pic-container">
					<img
						src="./LS01-banner.png"
						alt="product-banner"
						className="products-pic"
					/>
					<img
						src="./LS02-banner.png"
						alt="product-banner"
						className="products-pic"
					/>
				</div>
				<div
					style={{
						display: 'flex',
						padding: '2rem 0',
						justifyContent: 'flex-end',
					}}
					onClick={() => navigate('./products')}
					className="btn">
					<div style={{ fontSize: '1.4rem', marginRight: '1%' }}>
						shop now
					</div>
					<img
						src="./arrow-right-orange.png"
						alt="arrow-btn"
						style={{ width: 10 }}
					/>
				</div>
			</div>
		</div>
	);
}

export default Entry;
