import './Header&Footer.css';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useEffect, useRef } from 'react';

// import domain from '../utils/constant';

function Footer() {
	const footerRef = useRef(null);
	// const [footerHeight, setFooterHeight] = useState(0);

	useEffect(() => {
		if (footerRef.current) {
			const height = footerRef.current.getBoundingClientRect().height;
			// setFooterHeight(height);
			document.documentElement.style.setProperty(
				'--footerHeight',
				`${height}px`
			);
		}
	}, []);

	return (
		<footer ref={footerRef}>
			<div className="footer-menu-container">
				<div className="footer-menu-list-container">
					<Link className="footer-menu-item" to={"/gethelp"}>GET HELP</Link>
					<Link className="footer-menu-item" to={"/terms&conditions"}>TERMS & CONDITIONS</Link>
					<Link className="footer-menu-item" to={"/privacypolicy"}>PRIVACY POLICY</Link>
				</div>
				<div className="footer-menu-list-container">
					<img
						src="/instagram-brands-solid.svg"
						alt="ins icon"
						className="footer-menu-item media-icon"></img>
					<img
						src="/facebook-brands-solid.svg"
						alt="ins icon"
						className="footer-menu-item media-icon"></img>
					<img
						src="/youtube-brands-solid.svg"
						alt="ins icon"
						className="footer-menu-item media-icon"></img>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
