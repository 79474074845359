import './Login.css';
import { useNavigate } from 'react-router-dom';
import { signIn, signUp, confirmSignUp } from '../../AWS/cognito';
import { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../hooks/AuthProvider';
import Cookies from 'js-cookie';

function Login() {
	const [isSignUp, setIsSignUp] = useState(false);
	const [isConfirmAcc, setIsConfirmAcc] = useState(false);
	const [error, setError] = useState(false);
	const [email, setEmail] = useState('@');
	const [pw, setPw] = useState('');
	const [pw2, setPw2] = useState('');
	const [code, setCode] = useState('');
	const { userAuthed, contextAuth } = useContext(AuthContext);
	const navigate = useNavigate();
	useEffect(()=>{
		if(contextAuth.email){
		}
	},[contextAuth])
	function InputVerify() {
		if (isSignUp) {
			if (!email || !pw || !pw2) {
				setError('empty input')
				return false;
			}
			if (pw2 !== pw) {
				setError('pw not matched')
				return false;
			}
			if (pw2.length < 8) {
				setError('pw too short')
				return false;
			}
		}
		if (!email || !pw) {
			setError('empty input')
			return false;
		}

		return true;
	}
	async function handleSignUp(event) {
		event.preventDefault();
		const checked = InputVerify(email, pw, pw2);
		console.log({ checked });
		if (!checked) return;
		console.log({ email, pw, pw2 });
		try {
			await signUp(email, pw);
			setIsConfirmAcc(true);
		} catch (error) {
			setError(error.message);
			alert(`Sign up failed: ${error}`);
		}
	}
	async function handleSignIn(event) {
		event.preventDefault();
		const checked = InputVerify(email, pw, false);
		if (!checked) return;
		try {
			await signIn(email, pw);
			userAuthed({ email: email });
			Cookies.set('username', email, { expires: 150 });
			navigate('/user');
		} catch (error) {
			setError(error.message);
			alert(`Sign in failed: ${error}`);
		}
	}
	async function handleConfirmAcc(event) {
		event.preventDefault();
		if (!code) return;
		try {
			await confirmSignUp(email, code);
			navigate('/user');
		} catch (error) {
			setError(error.message);
			alert(`Sign in failed: ${error}`);
		}
	}
	return (
		<div className="page-root login">
			<div className="form-root-container">
				{!isConfirmAcc && (
					<form
						className="form-container left"
						onSubmit={isSignUp ? handleSignUp : handleSignIn}>
						<div className="title">
							{isSignUp ? 'Sign Up' : 'Sign In'}
						</div>
						<div className="input-container">
							<img src="../envelope-solid.svg" alt="email" />
							<input
								name="email"
								placeholder="Email"
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div className="input-container">
							<img src="../lock-solid.svg" alt="password" />
							<input
								name="pw"
								placeholder="Password"
								onChange={(e) => setPw(e.target.value)}
							/>
						</div>
						{isSignUp && (
							<div className="input-container">
								<img src="../lock-solid.svg" alt="password" />
								<input
									name="pw2"
									placeholder="Confirm Password"
									onChange={(e) => setPw2(e.target.value)}
								/>
							</div>
						)}
						<button type="submit">
							{isSignUp ? 'Sign Up' : 'Sign In'}
						</button>
						{error && <div className="input-error-text">{error}</div>}
						<div
							onClick={() => setIsSignUp(!isSignUp)}
							className="change-button">
							{isSignUp
								? 'already have account?'
								: 'dont have account yet'}
						</div>
					</form>
				)}
				{isConfirmAcc && email && (
					<form
						className="form-container left"
						onSubmit={handleConfirmAcc}>
						<div>A confirmation code has been sent to {email}.</div>
						<input
							name="code"
							placeholder="Code"
							onChange={(e) => setCode(e.target.value)}
						/>
						<button type="submit">Confirm</button>
					</form>
				)}
				<form className="form-container">
					<img src="../lock1.jpg" alt="product" className="pic" />
				</form>
			</div>
		</div>
	);
}

export default Login;
