import "./InnerPage.css";

function Franchise() {
  return (
    <div
      className="page-root"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div
        style={{
          background: "var(--primary) url(franchise-banner.png) no-repeat right",
          backgroundSize: "100% 100%",
        }}
        className="inner-page-title-box-container"
      >
        <p class="inner-page-title-box">Franchise</p>
      </div>
      <div className="inner-page-content-box">
        <div className="inner-page-slogan"> </div>
        <div className="inner-page-content">
			Coming Soon!
                    <div style={{ flex: 1 }} />
        </div>
      </div>
    </div>
  );
}

export default Franchise;
