import { useState } from "react";
import { changePassword } from "../../../AWS/cognito";

const ChangePassword=()=>{
    const [oldpw,setOldpw] = useState();
    const [newpw,setNewpw] = useState();
    const [errMsg,setErrMsg] = useState();
    const changepw = async ()=>{
        try{
            await changePassword(oldpw,newpw);
            setErrMsg("Successfully changed!");
        }catch(e){
            console.log(e.message)
            setErrMsg(e.message)
        }
    }
    return(
         <div className="user-page-content-container">
            <div
                className="sub-content-container">
                <div className="content-input-label">Old Password : </div>
                <input
                    id="textInput"
                    type="text"
                    className="user-input"
                    placeholder="Old password"
                    onChange={(e)=>setOldpw(e.target.value)}
                />
            </div>
            <div className="sub-content-container">
                <div className="content-input-label">New Password : </div>
                <input
                    id="textInput"
                    type="text"
                    className="user-input"
                    placeholder="New password"
                    onChange={(e)=>setNewpw(e.target.value)}
                />
            </div>
            <div className="error-box-container">
                <div className="error-text">{errMsg}</div>
            <div className="border-btn confirm-btn" onClick={changepw}>Confirm</div>
            </div>
        </div>
    )
}

export default ChangePassword;