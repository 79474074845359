import { DynamoDBClient } from '@aws-sdk/client-dynamodb';
import {
	DynamoDBDocumentClient,
	GetCommand,
	ScanCommand,
	UpdateCommand,
} from '@aws-sdk/lib-dynamodb';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import config from './config.json';

//for non login user
const guestClient = new DynamoDBClient({
	region: config.region,
	credentials: fromCognitoIdentityPool({
		clientConfig: { region: config.region },
		identityPoolId: config.identityPoolId,
	}),
});
const docClientGuest = DynamoDBDocumentClient.from(guestClient);

//for logged in user
const authorizedClient = () => {
	const IdToken = JSON.parse(localStorage.getItem('IdToken'));
	const client = new DynamoDBClient({
		region: config.region,
		credentials: fromCognitoIdentityPool({
			clientConfig: { region: config.region },
			identityPoolId: config.identityPoolId,
			logins: {
				'cognito-idp.us-east-1.amazonaws.com/us-east-1_ubvvIvzRJ': IdToken,
			},
		}),
	});
	return DynamoDBDocumentClient.from(client);
};

export const getUserInfo = async (EMAIL) => {
	console.log('DynamoDB:getUserInfo');

	const command = new GetCommand({
		TableName: 'locka.space_user',
		Key: {
			email: EMAIL,
		},
	});
	const authedDocClient = authorizedClient();

	const response = await authedDocClient.send(command);
	console.log(response);
	return response;
};

export const getProductsList = async () => {
	const command = new ScanCommand({
		TableName: 'locka.space_products',
	});

	const response = await docClientGuest.send(command);
	return response;
};

export const UpdateCart = async (username, itemList) => {
	const command = new UpdateCommand({
		TableName: 'locka.space_user',
		Key: {
			email: username,
		},
		ExpressionAttributeNames: {
			'#c': 'cart',
			// '#itemId': itemId,
			// '#itemColor': itemColor,
		},
		ExpressionAttributeValues: {
			':i': itemList,

			// ':inc': 1,
			// ':start': 0,
		},

		// UpdateExpression: `SET #c.#itemId.#itemColor = if_not_exists(#c.#itemId.#itemColor, :start) + :inc`,
		UpdateExpression: 'SET #c = :i ',

		ReturnValues: 'ALL_NEW',
	});
	const authedDocClient = authorizedClient();
	const response = await authedDocClient.send(command);
	return response.Attributes;
};
