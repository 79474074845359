import "./AboutUs.css";
import "./InnerPage.css";
import constants from "../utils/constants.json";

function AboutUs() {
  return (
    <div
      className="page-root"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div
        style={{
          background: "var(--primary) url(about-us-banner.png) no-repeat right",
          backgroundSize: "100% 100%",
        }}
        className="inner-page-title-box-container"
      >
        <p class="inner-page-title-box">About Us</p>
      </div>
      <div className="inner-page-content-box">
        <div className="inner-page-slogan">
          Learn more about us to get success together
        </div>
        <div className="inner-page-content">
          Imagine the way we live wlth fexlble storage space. Providing storage
          space is what we power. lt pushes us to constantly reimagine how we
          can create better for you. For all the stuff you want to store. For
          all the things you want to share. Across the entire world at the
          incredible speed of now.
          <br />
          <br />
          <div className="inner-page-sub-title">
            Read our full mission statement
          </div>
          We are a tech company that connects the physical and digital worlds to
          help make easy storage happen at the tap of a button. Because we
          believe in a world where easy storage should be accessible. In a way
          that's sustainable for our planet. We're not afraid of failure,
          because it makes us better, wiser, and stronger. And it makes us even
          more committed to do the right thing by our customers,local
          communities and cities, and our incredibly diverse set of interational
          partners.
          <br />
          <br />
          The idea for LOCKA was born in Hong Kong 2021 while we suffered by
          COVlD,and ever since then our DNA of reimagination and reinvention
          carries on. We've grewn intea global platform powering the movement of
          people and things in ever expanding ways.
          <br />
          <br />
          <div style={{ color: constants.blue, fontSize: "1.3rem" }}>
            At LOCKA, the pursuit of reimagination is never finished, never
            stops, and is always just beginning.
          </div>
          <div style={{ flex: 1 }} />
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
