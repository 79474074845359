import {
	CognitoIdentityProviderClient,
	SignUpCommand,
	InitiateAuthCommand,
	ConfirmSignUpCommand,
	ChangePasswordCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import config from './config.json';
import Cookies from 'js-cookie';

export const cognitoClient = new CognitoIdentityProviderClient({
	region: config.region,
});
export const signIn = async (username, password) => {
	try {
		const command = new InitiateAuthCommand({
			AuthFlow: 'USER_PASSWORD_AUTH',
			ClientId: config.clientId,
			AuthParameters: {
				USERNAME: username,
				PASSWORD: password,
			},
		});
		const { AuthenticationResult } = await cognitoClient.send(command);
		console.log('Sign in success: ', AuthenticationResult);

		if (AuthenticationResult) {
			localStorage.setItem(
				'AccessToken',
				JSON.stringify(AuthenticationResult.AccessToken)
			);
			localStorage.setItem(
				'RefreshToken',
				JSON.stringify(AuthenticationResult.RefreshToken)
			);
			localStorage.setItem(
				'IdToken',
				JSON.stringify(AuthenticationResult.IdToken)
			);
			return AuthenticationResult;
		}
		console.log('Sign in success: ', AuthenticationResult);
	} catch (error) {
		console.error('Error signing in: ', error);
		throw error;
	}
};

export const signUp = async (email, password) => {
	const params = {
		ClientId: config.clientId,
		Username: email,
		Password: password,
		UserAttributes: [
			{
				Name: 'email',
				Value: email,
			},
		],
	};
	try {
		const command = new SignUpCommand(params);
		const response = await cognitoClient.send(command);
		console.log('Sign up success: ', response);
		return response;
	} catch (error) {
		console.error('Error signing up: ', error);
		throw error;
	}
};

export const confirmSignUp = async (username, code) => {
	const params = {
		ClientId: config.clientId,
		Username: username,
		ConfirmationCode: code,
	};
	try {
		const command = new ConfirmSignUpCommand(params);
		await cognitoClient.send(command);
		console.log('User confirmed successfully');
		return true;
	} catch (error) {
		console.error('Error confirming sign up: ', error);
		throw error;
	}
};

export const refreshToken = async () => {
	const token = JSON.parse(localStorage.getItem('RefreshToken'));
	try {
		const command = new InitiateAuthCommand({
			AuthFlow: 'REFRESH_TOKEN',
			ClientId: config.clientId,
			AuthParameters: {
				REFRESH_TOKEN: token,
			},
		});
		const { AuthenticationResult } = await cognitoClient.send(command);
		console.log('Token refreshed: ', AuthenticationResult);

		if (AuthenticationResult) {
			localStorage.setItem(
				'AccessToken',
				JSON.stringify(AuthenticationResult.AccessToken)
			);
			localStorage.setItem(
				'IdToken',
				JSON.stringify(AuthenticationResult.IdToken)
			);
			console.log('Token stored');

			return AuthenticationResult;
		}
	} catch (error) {
		console.error('Error refreshToken: ', error);

		Cookies.remove('username');
		localStorage.clear();

		throw error;
	}
};

export const changePassword = async (oldPw, newPw) => {
	const token = JSON.parse(localStorage.getItem('AccessToken'));

	try {
		const command = new ChangePasswordCommand({
			AccessToken: token,
			PreviousPassword: oldPw,
			ProposedPassword: newPw,
		});
		const resp = await cognitoClient.send(command);
		console.log('Pw changed: ', resp);
	} catch (error) {
		console.error('Error changing pw: ', error);
		throw error;
	}
};
