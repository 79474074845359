import { Link } from 'react-router-dom';
import './User.css';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../hooks/AuthProvider';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import ChangePassword from './components/ChangePassword';
import DeliveryAddress from './components/DeliveryAddress';
import PaymentDetails from './components/PaymentDetails';
import OrderHistory from './components/OrderHistory';
import constants from '../../utils/constants.json'

function User() {
	const { contextAuth, logOut } = useContext(AuthContext);
	const [contentDisplay, setContentDisplay] = useState()
	const navigate = useNavigate();
	useEffect(() => {
		if(!contextAuth.email){
			// navigate('/user/login');
		}
		console.log(contextAuth.email);
	}, [contextAuth]);

	const logout = () => {
		Cookies.remove('username');
		localStorage.clear();
		logOut();
		navigate('/user/login');
	};

	return (
		<div className="page-root">
			{!contextAuth.email && <Link to={'/user/login'} className='login-btn'>Login</Link>}
			{contextAuth.email && (
				<div className="user-page-root-margin">
					<div className='user-page-index-container'>
						<div className="profile-header-container">
							<div className="profile-email">{contextAuth.email}</div>
							<div onClick={logout} className="border-btn">
								Log Out
							</div>
						</div>
						<div className="profile-tab-container" onClick={()=>setContentDisplay('OrderHistory')}>
							<div className="profile-tab-title" style={{color:contentDisplay==='OrderHistory'?constants.orange:"white"}}>Order History</div>
							<img
								alt="arrow-btn"
								src="./angle-down-solid.svg"
								className="arrow-btn"></img>
						</div>
						<div className="profile-tab-container" onClick={()=>setContentDisplay('PaymentDetails')}>
							<div className="profile-tab-title" style={{color:contentDisplay==='PaymentDetails'?constants.orange:"white"}}>Payment Details</div>
							<img
								alt="arrow-btn"
								src="./angle-down-solid.svg"
								className="arrow-btn"></img>
						</div>
						<div className="profile-tab-container" onClick={()=>setContentDisplay('DeliveryAddress')}>
							<div className="profile-tab-title" style={{color:contentDisplay==='DeliveryAddress'?constants.orange:"white"}}>Delivery Address</div>
							<img
								alt="arrow-btn"
								src="./angle-down-solid.svg"
								className="arrow-btn"></img>
						</div>
						<div className="profile-tab-container" onClick={()=>setContentDisplay('ChangePassword')}>
							<div className="profile-tab-title" style={{color:contentDisplay==='ChangePassword'?constants.orange:"white"}}>Change Password</div>
							<img
								alt="arrow-btn"
								src="./angle-down-solid.svg"
								className="arrow-btn"></img>
						</div>
					</div>
					<div className='user-page-contents-container' style={{display:contentDisplay==='ChangePassword'?"block":"none"}}>
						<ChangePassword/>
					</div>
					<div className='user-page-contents-container' style={{display:contentDisplay==='DeliveryAddress'?"block":"none"}}>
						<DeliveryAddress/>
					</div>
					<div className='user-page-contents-container' style={{display:contentDisplay==='OrderHistory'?"block":"none"}}>
						<OrderHistory/>
					</div>
					<div className='user-page-contents-container' style={{display:contentDisplay==='PaymentDetails'?"block":"none"}}>
						<PaymentDetails/>
					</div>
				</div>
			)}
		</div>
	);
}

export default User;
