import { useState, useContext } from 'react';
import { AuthContext } from '../../../hooks/AuthProvider';

const DeliveryAddress = () => {
	const [errMsg, setErrMsg] = useState('');
	const [newAddress, setNewAddress] = useState();
	const { contextAuth } = useContext(AuthContext);
	const addressArray = contextAuth.address || [];
	const [editMode, setEditMode] = useState(false);

	const changeAddress = async () => {
		console.log('change address', newAddress);
		setErrMsg('');
		setEditMode(false);
	};
	return (
		<div className="user-page-content-container">
			{addressArray.map((e, i) => (
				<div style={{ display: 'flex', alignItems: 'center' }} key={i}>
					<div>{i + 1}.</div>
					<div
						key={i}
						className="content-row-text"
						style={{ marginLeft: '1rem' }}>
						{e}
					</div>
					<div
						style={{
							color: 'grey',
							fontSize: '0.8rem',
							display: i === 0 ? 'block' : 'none',
							marginLeft: '0.5rem',
						}}>
						(default)
					</div>
					<img
						src="./pen-to-square-solid.svg"
						alt="edit-btn"
						style={{
							marginLeft: '1rem',
							height: '1rem',
							display: editMode ? 'flex' : 'none',
						}}
						className="btn"
					/>
					<img
						src="./trash-solid.svg"
						alt="edit-btn"
						style={{
							marginLeft: '1rem',
							height: '1rem',
							display: editMode ? 'flex' : 'none',
						}}
						className="btn"
					/>
				</div>
			))}
			<div
				className="border-btn"
				onClick={() => setEditMode(true)}
				style={{
					marginTop: '4%',
					display: editMode ? 'none' : 'block',
					textAlign: 'center',
				}}>
				Edit
			</div>
			<div
				className="sub-content-container"
				style={{ display: editMode ? 'flex' : 'none' }}>
				<input
					id="textInput"
					type="text"
					className="user-input"
					placeholder="New address"
					onChange={(e) => setNewAddress(e.target.value)}
				/>
				<div
					className="border-btn"
					style={{ padding: '0.3rem 0.5rem', marginLeft: '1rem' }}
					onClick={changeAddress}>
					Add
				</div>
			</div>
			<div style={{ width: '100%', textAlign: 'left' }}>{errMsg}</div>

			<div
				className="comfirm-box-container"
				style={{ display: editMode ? 'flex' : 'none' }}>
				<div
					className="border-btn confirm-btn"
					onClick={() => setEditMode(false)}>
					Cancel
				</div>
				<div className="border-btn confirm-btn" onClick={changeAddress}>
					Save Changes
				</div>
			</div>
		</div>
	);
};

export default DeliveryAddress;
