// import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import { BrowserRouter, Routes, Route,useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import AboutUs from './pages/AboutUs';
import Franchise from './pages/Franchise';
import ContactUs from './pages/ContactUs';
import Entry from './pages/Entry';
import Products from './pages/products/Products';
import User from './pages/user/User';
import Footer from './components/Footer';
import Login from './pages/user/Login';
import AuthProvider from './hooks/AuthProvider';
import ProductsProvider from './hooks/ProductsProvider';
import Cart from './pages/user/Cart';
import GetHelp from './pages/GetHelp';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import Checkout from './pages/user/Checkout';
import News from './pages/News';
import Promotions from './pages/Promotions';

function App() {
	const ScrollToTop = () => {
		const { pathname } = useLocation();
	  
		useEffect(() => {
		  window.scrollTo(0, 0);
		}, [pathname]);
	  
		return null;
	  };
	  
	return (
		<BrowserRouter>
			<div className="App">
				<ProductsProvider>
					<AuthProvider>
						<div className="root-margin">
							<Header />
							<ScrollToTop />
							<Routes>
								<Route path="/" element={<Entry />} />
								<Route path="/aboutus" element={<AboutUs />} />
								<Route path="/franchise" element={<Franchise />} />
								<Route path="/contactus" element={<ContactUs />} />
								<Route path="/promotions" element={<Promotions/>}/>
								<Route path="/news" element={<News/>}/>
								<Route path="/products" element={<Products />} />
								<Route path="/user" element={<User />} />
								<Route path="/user/login" element={<Login />} />
								<Route path="/user/cart" element={<Cart />} />
								<Route path="/gethelp" element={<GetHelp />} />
								<Route
									path="/privacypolicy"
									element={<PrivacyPolicy />}
								/>
								<Route path="/terms&conditions" element={<Terms />} />
								<Route
									path="user/cart/checkout"
									element={<Checkout />}
								/>
							</Routes>
							<Footer />
						</div>
					</AuthProvider>
				</ProductsProvider>
			</div>
		</BrowserRouter>
	);
}

export default App;
