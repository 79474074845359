import './Products.css';
import { ProductsContext } from '../../hooks/ProductsProvider';
import { useContext, useEffect, useState } from 'react';
// import { UpdateCart } from '../../AWS/dynamoDB';
import { AuthContext } from '../../hooks/AuthProvider';

function Products() {
	const { contextProducts } = useContext(ProductsContext);
	const { updateCartToDB, contextAuth } = useContext(AuthContext);
	const [products, setProducts] = useState([]);
	const [cart, setCart] = useState();
	const [colorIndex, setColorIndex] = useState([0]);
	const [errMsg, setErrMsg] = useState('');
	useEffect(() => {
		if (contextProducts) {
			setColorIndex(Array(contextProducts.length).fill(0));
			let productsArray = contextProducts;
			for (let i = 0; i < productsArray.length; i++) {
				productsArray[i].colors.unshift('thumbnail');
			}
			setProducts(productsArray);
		}
	}, [contextProducts]);
	useEffect(() => {
		const nonloginUserCart = JSON.parse(localStorage.getItem('cart')) || null;
		if (contextAuth.cart) {
			setCart(contextAuth.cart);
		} else if (nonloginUserCart) {
			setCart(nonloginUserCart);
		} else {
			setCart({});
		}
	}, [contextAuth]);

	const changeColor = (productI, colorI) => {
		setColorIndex((prev) => {
			const newColorIndex = [...prev];
			newColorIndex[productI] = colorI;
			return newColorIndex;
		});
	};
	const addToCart = async (productId, color) => {
		if (color === 'thumbnail') {
			setErrMsg('select a color!');
			alert(errMsg);
			return;
		}
		const itemList = cart || {};
		if (itemList[productId]) {
			if (itemList[productId][color]) {
				itemList[productId][color] += 1;
			} else {
				itemList[productId][color] = 1;
			}
		} else {
			itemList[productId] = {
				[color]: 1,
			};
		}
		console.log({ itemList });
		setCart(itemList);
		if (contextAuth.email) {
			try {
				await updateCartToDB(itemList);
			} catch (e) {
				console.log(e.message);
			}
		} else {
			localStorage.setItem('cart', JSON.stringify(cart));
		}
	};
	function restructureDesStr(des) {
		return des.split('\n');
	}
	return (
		<div className="page-root">
			{products &&
				products.map((item, index) => (
					<div className="products-container" key={index}>
						<div className="product-pic-container">
							<img
								alt="product-pic"
								src={`/products/${item.productId}/${
									item.colors[colorIndex[index]]
								}.jpg`}
								className="product-pic"
							/>
							<div className="indicator-container">
								{item.colors.map((color, i) => (
									<div
										style={{
											backgroundColor:
												i === colorIndex[index]
													? 'white'
													: '#968d8d',
											height: 10,
											width: 10,
											margin: 8,
											marginTop: 15,
											borderRadius: '50%',
										}}
										key={i}
									/>
								))}
							</div>
						</div>
						<div className="color-option-container">
							{item.colors.map((color, colori) => (
								<div
									style={{
										display: color === 'thumbnail' ? 'none' : 'flex',
										backgroundColor: `#${color}`,
										width: 35,
										height: 35,
										borderColor:
											colori === colorIndex[index]
												? 'white'
												: '#968d8d',
										borderStyle: 'solid',
										borderWidth: 2,
										marginBottom: 6,
									}}
									key={`${colori}-${item.productId}`}
									onClick={() => {
										changeColor(index, colori);
									}}
								/>
							))}
						</div>
						<div className="product-description-container">
							<div className="product-id-container">
								<div className="product-id">{item.productId}</div>
								<div className="product-price">${item.price}</div>
							</div>
							<div className="product-title">{item.title}</div>
							{restructureDesStr(item.des).map((element, i) => (
								<div className="product-des" key={i}>
									{element}
								</div>
							))}
							{cart && (
								<div className="add-to-cart-container">
									<div
										className="add-to-cart-btn"
										onClick={() =>
											addToCart(
												item.productId,
												item.colors[colorIndex[index]]
											)
										}>
										Add To Cart
									</div>
									<div>{errMsg}</div>
								</div>
							)}
						</div>
					</div>
				))}
		</div>
	);
}

export default Products;
