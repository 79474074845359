import { useEffect, createContext, useState } from 'react';
import { getProductsList } from '../AWS/dynamoDB';

export const ProductsContext = createContext(null);

const ProductsProvider = ({ children }) => {
	const [contextProducts, setProducts] = useState();

	const initProductsList = async () => {
		try {
			const resp = await getProductsList();
			setProducts(resp.Items);
			console.log(resp.Items);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		initProductsList();
	}, []);
	const contextValue = { contextProducts };
	return (
		<ProductsContext.Provider
			value={contextValue}
			displayName="Products Context">
			{children}
		</ProductsContext.Provider>
	);
};

export default ProductsProvider;
