import { useEffect, createContext, useState } from 'react';
import Cookies from 'js-cookie';
import { getUserInfo, UpdateCart } from '../AWS/dynamoDB';
import { refreshToken } from '../AWS/cognito';

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
	const [contextAuth, setAuth] = useState({ email: false });
	const userAuthed = (item) => {
		setAuth(item);
		console.log({ contextAuth });
	};
	const getUserProfileFromDB = async () => {
		const username = Cookies.get('username');
		if (username) {
			try {
				const resp = await getUserInfo(username);
				setAuth(resp.Item);
				console.log(resp.Item);
			} catch (e) {
				console.log(e);
				const tokenExpiredMsg = 'Invalid login token';
				if (e.message.includes(tokenExpiredMsg)) {
					try {
						await refreshToken();
					} catch (refreshE) {
						console.log('login session expired');
					}
				}
			}
		}
	};

	const updateCartToDB = async (itemList) => {
		try {
			const updatedProfile = await UpdateCart(contextAuth.email, itemList);
			console.log({ updatedProfile });
			setAuth(updatedProfile);
			console.log({ contextAuth });
		} catch (e) {
			return e;
		}
	};

	const logOut = () => {
		setAuth({ email: false });
	};
	useEffect(() => {
		getUserProfileFromDB();
	}, [contextAuth.email]);
	const contextValue = { contextAuth, userAuthed, updateCartToDB, logOut };
	return (
		<AuthContext.Provider value={contextValue} displayName="Auth Context">
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
