import '../products/Products.css';
import { useEffect, useContext, useState } from 'react';
import { AuthContext } from '../../hooks/AuthProvider';
import { ProductsContext } from '../../hooks/ProductsProvider';
import { UpdateCart } from '../../AWS/dynamoDB';
import constants from '../../utils/constants.json';
import { Link } from 'react-router-dom';

function Cart() {
	const { contextAuth } = useContext(AuthContext);
	const { contextProducts } = useContext(ProductsContext);

	const [cartItem, setCartItem] = useState({});
	const [cartItemList, setCartItemList] = useState([]);
	console.log({ cartItem });
	const updateCartToServer = async (cart) => {
		console.log({ cart });
		try {
			await UpdateCart(cart);
		} catch (e) {
			console.log(e);
		}
		console.log('updateCartToServer');
	};
	useEffect(() => {
		const nonloginUserCart = JSON.parse(localStorage.getItem('cart')) || null;
		if (contextAuth.cart) {
			setCartItem(contextAuth.cart);
		} else if (nonloginUserCart) {
			setCartItem(nonloginUserCart);
		} else {
			setCartItem({});
		}
	}, [contextAuth]);

	useEffect(() => {
		setCartItemList(
			cartItem
				? Object.entries(cartItem).map(([key, value]) => ({
						id: key,
						...value,
				  }))
				: null
		);
		console.log('useeffect');
		const handleBeforeUnload = (event) => {
			// Custom logic before page unload
			console.log('handleBeforeUnload');
			event.preventDefault(); // Required for Chrome
			event.returnValue = ''; // Required for Chrome
			updateCartToServer(cartItem); // Send cart data to the server
		};
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [cartItem]);

	const findPrice = (id) => {
		const item = contextProducts.find((element) => element.productId === id);
		return item ? item.price : null;
	};
	const findTitle = (id) => {
		const item = contextProducts.find((element) => element.productId === id);
		return item ? item.title : null;
	};
	const colorList = (id) => {
		const list = Object.keys(cartItem[id]);
		return list ? list : null;
	};
	const deleteItem = (id, color) => {
		let newCartItem = cartItem;
		delete newCartItem[id][color];
		setCartItem(newCartItem);
	};
	return (
		<div
			className="page-root"
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			<div style={{ color: constants.orange, fontSize: '1.7rem' }}>CART</div>
			<div
				className="border-btn btn"
				style={{ marginTop: '1%', width: '70%' }}>
				EDIT
			</div>
			<Link
				className="border-btn btn"
				style={{ marginTop: '1%', width: '70%' }}
				to={'checkout'}>
				CHECKOUT
			</Link>
			{cartItem &&
				contextProducts &&
				cartItemList.map((item, index) =>
					colorList(item.id).map((color, i) => (
						<div
							className="products-container"
							key={i}
							style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
							<div
								className="product-pic-container"
								style={{ width: '20%' }}>
								<img
									alt="product-pic"
									src={`/products/${item.id}/${color}.jpg`}
									className="product-pic"
								/>
							</div>
							<div
								className="product-description-container"
								style={{ marginLeft: '2rem' }}>
								<div className="product-title">
									{findTitle(item.id)}
								</div>
								<div>
									<div className="product-des">{item.id}</div>
									<div
										className="product-des"
										style={{
											backgroundColor: `#${color}`,
											width: 20,
											height: 20,
											borderColor: '#968d8d',
											borderStyle: 'solid',
											borderWidth: 2,
											marginBottom: 6,
											marginLeft: 10,
										}}></div>
								</div>
								<div>
									<div className="product-des">
										${findPrice(item.id)}
									</div>
									<div style={{ display: 'flex', marginLeft: '3rem' }}>
										<div style={{ display: 'flex' }}>
											<div
												className="product-des btn"
												style={{
													color:
														cartItem[item.id][color] === 1
															? '#968d8d'
															: 'white',
												}}>
												-
											</div>
											<div
												className="product-des"
												style={{
													marginLeft: '1rem',
													marginRight: '1rem',
												}}>
												{cartItem[item.id][color]}
											</div>
											<div className="product-des btn">+</div>
										</div>
										<img
											src="/trash-solid.svg"
											alt="delete"
											style={{ height: 20, marginLeft: '2rem' }}
											className="btn"
											onClick={() => deleteItem(item.id, color)}
										/>
									</div>
								</div>
							</div>
						</div>
					))
				)}
		</div>
	);
}

export default Cart;
